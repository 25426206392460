import React, { useState, useEffect, useCallback } from 'react';
import useBackgroundStyles from './useBackgroundStyles';
import QuestionsPanel from './QuestionsPanel';
import Grid from './Grid';
import './App.css';
import RichTextEditor from './modules/RichTextEditor';
import ArchiveList from './ArchiveList';

const App = () => {
  const [archiveName, setArchiveName] = useState('crossword'); // Имя архива по умолчанию
  const [showPopup, setShowPopup] = useState(false); // Отображение попапа
  const [showArchiveList, setShowArchiveList] = useState(false); // Показать список архивов
  const [gridSize, setGridSize] = useState(5); // Начальный размер сетки
  const [startRow, setStartRow] = useState(0);
  const [startCol, setStartCol] = useState(0);
  const [newAnswer, setNewAnswer] = useState('');
  const length = newAnswer.length;
  const [newQuestion, setNewQuestion] = useState('');
  const [direction, setDirection] = useState('horizontal');
  const { selectedBackground, backgroundImages, changeColors, titleCloudColors, CloudColors, handleBackgroundChange } = useBackgroundStyles();
  const [title, setTitle] = useState("Заголовок кроссворда");
  const [content, setContent] = useState("Разгадай кроссворд.");
  const [paragraph, setParagraph] = useState("Здесь при необходимости может быть ваш дополнительный текст.");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingContent, setIsEditingContent] = useState(false);
  const [isEditingParagraph, setIsEditingParagraph] = useState(false);
  const [showNewQuestion, setShowNewQuestion] = useState(false)
  const [questions, setQuestions] = useState([
    /*  { text: "Capitalss of France?", direction: 'horizontal', start: [0, 1], length: 5, answer: 'PARIS' },
      { text: "The largest planet in the Solar System?", direction: 'vertical', start: [2, 2], length: 7, answer: 'JUPITER' },
      { text: "The chemical symbol for gold?", direction: 'horizontal', start: [3, 4], length: 2, answer: 'AU' }*/
  ]);

  const createEmptyGrid = useCallback((size) => {
    return Array.from({ length: size }, () => Array.from({ length: size }, () => ''));
  }, []);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleContentChange = (e) => setContent(e.target.value);

  const [grid, setGrid] = useState(createEmptyGrid(5));

  // Функция для увеличения размера сетки при необходимости
  const adjustGridSize = useCallback((questions) => {
    let maxRow = 5;
    let maxCol = 5;

    questions.forEach((question) => {
      const [startRow, startCol] = question.start;
      if (question.direction === 'horizontal') {
        maxCol = Math.max(maxCol, startCol + question.length);
        maxRow = Math.max(maxRow, startRow + 1);
      } else if (question.direction === 'vertical') {
        maxRow = Math.max(maxRow, startRow + question.length);
        maxCol = Math.max(maxCol, startCol + 1);
      }
    });

    setGridSize(Math.max(maxRow, maxCol));
    setGrid(createEmptyGrid(Math.max(maxRow, maxCol)));
  }, [setGridSize, setGrid, createEmptyGrid]);

  useEffect(() => {
    adjustGridSize(questions);
  }, [questions, adjustGridSize]);

  // Функция для обновления сетки с новыми ответами
  const updateGridWithAnswers = (newQuestions) => {
    const newGrid = [...grid];

    // Проверяем, что каждая строка имеет нужное количество столбцов
    for (let i = 0; i < newGrid.length; i++) {
      if (!Array.isArray(newGrid[i])) {
        newGrid[i] = Array(gridSize).fill('');
      } else if (newGrid[i].length < gridSize) {
        newGrid[i] = [...newGrid[i], ...Array(gridSize - newGrid[i].length).fill('')];
      }
    }

    newQuestions.forEach((question) => {
      const { start, direction, answer } = question;
      const [startRow, startCol] = start;

      for (let i = 0; i < answer.length; i++) {
        if (direction === 'horizontal') {
          if (newGrid[startRow]) {
            newGrid[startRow][startCol + i] = answer[i];
          }
        } else if (direction === 'vertical') {
          if (newGrid[startRow + i]) {
            newGrid[startRow + i][startCol] = answer[i];
          }
        }
      }
    });
    setGrid(newGrid);
  };

  const handleCellChange = (rowIndex, cellIndex, value) => {
    const newGrid = [...grid];
    newGrid[rowIndex][cellIndex] = value.toUpperCase();
    setGrid(newGrid);
  };

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);

  const handleDownload = async () => {
    closePopup();
    const response = await fetch('/generate-archive', { // Относительный путь
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        questions,
        grid,
        background: selectedBackground,
        title,
        content,
        paragraph,
        archiveName
      }),
    });

    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${archiveName}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim() !== '' && startRow >= 0 && startCol >= 0 && length > 0) {
      const newQuestions = [...questions, { text: newQuestion, direction, start: [startRow, startCol], length, answer: newAnswer.toUpperCase() }];
      newQuestions.sort((a, b) => {
        if (a.direction === 'horizontal' && b.direction === 'vertical') {
          return -1;
        } else if (a.direction === 'vertical' && b.direction === 'horizontal') {
          return 1;
        }
        return 0;
      });

      setQuestions(newQuestions);
      adjustGridSize(newQuestions);
      updateGridWithAnswers(newQuestions);
      setNewQuestion('');
      setNewAnswer('');
    }
  };

  return (
    <div
      className="app"
      style={{
        backgroundImage: `url(${selectedBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {showArchiveList ? (
        <ArchiveList
          onBack={() => setShowArchiveList(false)}
          onOpenArchive={(archiveId) => {
            fetch(`/archives/${archiveId}`) // Относительный путь
              .then((res) => res.json())
              .then((data) => {
                setArchiveName(data.name);
                setQuestions(typeof data.questions === 'string' ? JSON.parse(data.questions) : data.questions);
                setGrid(typeof data.grid === 'string' ? JSON.parse(data.grid) : data.grid);
                setTitle(data.title);
                setContent(data.content);
                setParagraph(data.paragraph);
                setShowArchiveList(false);
              })
              .catch((error) => {
                console.error('Ошибка при загрузке архива:', error);
              });
          }}
        />
      ) : (
        <div className='app-body'>
          <div className='open-cross-list'>
            <button onClick={() => setShowArchiveList(true)}>Список архивов</button>
          </div>
          <div className="eom-title" style={{ background: titleCloudColors[selectedBackground] }}>
            <div className='edit-title'>
              {isEditingTitle ? (
                <input
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  onBlur={() => setIsEditingTitle(false)}
                />
              ) : (
                <>
                  <h1>{title}</h1>
                  <button onClick={() => setIsEditingTitle(true)}>✏️</button>
                </>
              )}
            </div>
          </div>
          <div className="eom-content">
            <img src={backgroundImages[selectedBackground]} alt="Crossword Icon" className="eom-image" />
            <div className="eom-text">
              <div className='edit-content'>
                {isEditingContent ? (
                  <textarea
                    value={content}
                    onChange={handleContentChange}
                    onBlur={() => setIsEditingContent(false)}
                  />
                ) : (
                  <>
                    <h2 style={{ color: changeColors[selectedBackground] }}>{content}</h2>
                    <button onClick={() => setIsEditingContent(true)}>✏️</button>
                  </>
                )}
              </div>
              <div className="edit-paragraph">
                {isEditingParagraph ? (
                  <RichTextEditor
                    initialValue={paragraph}
                    onSave={(newValue) => {
                      setParagraph(newValue); // Сохраняем изменения
                      setIsEditingParagraph(false); // Закрываем редактор
                    }}
                    onCancel={() => setIsEditingParagraph(false)} // Просто закрываем редактор
                  />
                ) : (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                    <button onClick={() => setIsEditingParagraph(true)}>✏️</button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="background-selection">
            <label>Выберите фон:</label>
            <select value={selectedBackground} onChange={handleBackgroundChange}>
              <option value="assets/background1.png">Начальные классы</option>
              <option value="assets/background2.png">Средние классы</option>
              <option value="assets/background3.png">Старшие классы</option>
            </select>
          </div>

          <div className="workspace">
            <QuestionsPanel
              questions={questions}
              setQuestions={setQuestions}
              selectedBackground={selectedBackground}
              changeColors={changeColors}
              CloudColors={CloudColors}
            />

            <div className="grid-wrapper" style={{ borderColor: changeColors[selectedBackground] }}>
              <Grid gridSize={gridSize} grid={grid} questions={questions} onCellChange={handleCellChange} />
            </div>
          </div>

          <div className="controls">

            <button onClick={() => setShowNewQuestion(!showNewQuestion)}>
              {showNewQuestion ? 'Скрыть' : 'Добавление вопроса'}
            </button>

            {showNewQuestion && (
              <div className="add-question">

                <RichTextEditor
                  initialValue={newQuestion}
                  onSave={(value) => setNewQuestion(value)} // Сохраняем вопрос в state
                  onCancel={() => setNewQuestion('')} // Сбрасываем текст вопроса при отмене
                />

                <select value={direction} onChange={(e) => setDirection(e.target.value)}>
                  <option value="horizontal">Горизонталь</option>
                  <option value="vertical">Вертикаль</option>
                </select>

                <input
                  type="number"
                  placeholder="Начальная строка"
                  value={startRow}
                  onChange={(e) => setStartRow(Number(e.target.value))}
                />

                <input
                  type="number"
                  placeholder="Начальный столбик"
                  value={startCol}
                  onChange={(e) => setStartCol(Number(e.target.value))}
                />

                <input
                  type="text"
                  value={newAnswer}
                  placeholder="Введите ответ"
                  onChange={(e) => setNewAnswer(e.target.value)}
                />

                <div>
                  <p>Длина ответа: {length}</p>
                </div>

                <button onClick={handleAddQuestion}>Добавить вопрос</button>
              </div>
            )}

            <div className="download-wrapper">
              <button onClick={openPopup}>Сохранить и скачать</button>
            </div>

          </div>
        </div>
      )}

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Введите название архива</h3>
            <input
              type="text"
              value={archiveName}
              onChange={(e) => setArchiveName(e.target.value)}
            />
            <div className="popup-buttons">
              <button onClick={handleDownload}>Подтвердить</button>
              <button onClick={closePopup}>Отмена</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
