import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addEmojiButton } from './emojiPool';

const RichTextEditor = ({ initialValue = '', onSave, onCancel }) => {
    const [tempValue, setTempValue] = useState(initialValue);
    const quillRef = useRef(null);

    useEffect(() => {
        addEmojiButton(quillRef);
    }, []);

    const modules = {
        toolbar: [
            [{ font: [] }, { size: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const formats = [
        'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'script', 'header', 'blockquote', 'code-block',
        'list', 'bullet',
        'align', 'link', 'image', 'video',
    ];

    const handleSave = () => {
        onSave(tempValue);
    };

    const handleCancel = () => {
        setTempValue(initialValue);
        onCancel();
    };

    return (
        <div>
            <ReactQuill
                ref={quillRef}
                value={tempValue}
                onChange={setTempValue}
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder="Введите текст"
            />
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <button onClick={handleSave} style={{ marginRight: '10px' }}>
                    Сохранить
                </button>
                <button onClick={handleCancel}>
                    Отменить
                </button>
            </div>
        </div>
    );
};

export default RichTextEditor;
