import './App.css';
const Grid = ({ gridSize, questions, onCellChange }) => {
    const containerSize = 500;
    const cellSize = Math.max(20, containerSize / gridSize);

    const renderCell = (rowIndex, cellIndex) => {
        for (const [index, question] of questions.entries()) {
            const [startRow, startCol] = question.start;
            const answer = question.answer;

            // Проверяем, если это ячейка для отображения номера вопроса (на -1 позиции)
            const isNumberCell = 
                (question.direction === 'horizontal' && rowIndex === startRow && cellIndex === startCol - 1) ||
                (question.direction === 'vertical' && cellIndex === startCol && rowIndex === startRow - 1);

            // Отображаем ячейку с номером перед первой ячейкой ответа
            if (isNumberCell) {
                return (
                    <div className="crossword-num" style={{ position: 'relative', width: `${cellSize}px`, height: `${cellSize}px` }} key={`${rowIndex}-${cellIndex}`}>
                        {index + 1}
                    </div>
                );
            }

            // Горизонтальный вопрос
            if (question.direction === 'horizontal' && rowIndex === startRow && cellIndex >= startCol && cellIndex < startCol + question.length) {
                const answerIndex = cellIndex - startCol;
                return (
                    <input
                        className="grid-cell answer-cell"
                        style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
                        key={`${rowIndex}-${cellIndex}`}
                        value={answer[answerIndex] || ''}
                        maxLength={1}
                        onChange={(e) => onCellChange(rowIndex, cellIndex, e.target.value)}
                    />
                );
            }

            // Вертикальный вопрос
            if (question.direction === 'vertical' && cellIndex === startCol && rowIndex >= startRow && rowIndex < startRow + question.length) {
                const answerIndex = rowIndex - startRow;
                return (
                    <input
                        className="grid-cell answer-cell"
                        style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
                        key={`${rowIndex}-${cellIndex}`}
                        value={answer[answerIndex] || ''}
                        maxLength={1}
                        onChange={(e) => onCellChange(rowIndex, cellIndex, e.target.value)}
                    />
                );
            }
        }

        // Пустая ячейка (неиспользуемая)
        return (
            <div
            className="grid-cell unused-cell"
            style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
            key={`${rowIndex}-${cellIndex}`}
        />

        );
    };

    return (
        <div className="grid" style={{ '--grid-size': gridSize, '--cell-size': `${cellSize}px` }}>
            {Array.from({ length: gridSize }).map((_, rowIndex) =>
                Array.from({ length: gridSize }).map((_, cellIndex) => renderCell(rowIndex, cellIndex))
            )}
        </div>

    );
};

export default Grid;
