import React, { useEffect, useState } from 'react';
import './styles/ArchiveList.css';

const ArchiveList = ({ onBack, onOpenArchive }) => {
    const [archives, setArchives] = useState([]);

    useEffect(() => {
        fetch('/archives')
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => setArchives(data))
            .catch((err) => console.error('Ошибка загрузки архивов:', err));
    }, []);

    return (
        <div className='archive-list'>
            <div className='title-list'>
                <h1 className='archive-list-title'>Список архивов</h1>
                <button onClick={onBack} className="back-button">Назад</button>
            </div>

            <ul className='list'>
                {archives.map((archive) => (
                    <li className='cross-list' key={archive.id}>
                        <div className='action-list'>
                            <span>{archive.name}</span>
                            <span style={{ color: 'gray' }}>(Создан: {new Date(archive.created_at).toLocaleString()})</span>
                        </div>
                        <div className='action-button'>
                            <button onClick={() => onOpenArchive(archive.id)}>Открыть</button>
                            <a href={`/download-archive/${archive.id}`} download>
                                Скачать
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ArchiveList;
