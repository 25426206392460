import { useState } from "react";

const useBackgroundStyles = (initialBackground)=>{
    const [selectedBackground, setSelectedBackground] = useState('assets/background1.png'); // по умолчанию

     // Ассоциируем изображения с фонами
  const backgroundImages = {
    'assets/background1.png': 'assets/pers1.png',
    'assets/background2.png': 'assets/pers2.png',
    'assets/background3.png': 'assets/pers3.png',
  };

  const changeColors = {
    'assets/background1.png': '#46B755',
    'assets/background2.png': '#0c6ed6',
    'assets/background3.png': '#ff790b'
  };

  const titleCloudColors = {
    'assets/background1.png': 'linear-gradient(to right, #46b755, #B0E881)',
    'assets/background2.png': 'linear-gradient(to right, #0c6ed6, #60B9A6)',
    'assets/background3.png': 'linear-gradient(to right, #ff790b, #ffb546)'
  };

  const CloudColors = {
    'assets/background1.png': 'linear-gradient(to right, #B0E881, #46b755)',
    'assets/background2.png': 'linear-gradient(to right, #60B9A6, #0c6ed6)',
    'assets/background3.png': 'linear-gradient(to right, #ffb546, #ff790b)'
  };

  const handleBackgroundChange = (e) => {
    setSelectedBackground(e.target.value);
  };

  

  return {
    selectedBackground,
    backgroundImages,
    changeColors,
    titleCloudColors,
    CloudColors,
    handleBackgroundChange,
  };
};

export default useBackgroundStyles